var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import IMask from "imask/esm/imask";
import ApplicationController from "@/controllers/application_controller";
export default class field_validation_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "masked");
    __publicField(this, "type");
  }
  initialize() {
  }
  connect() {
    console.log("this?.fieldTarge", this.fieldTarget);
    if (this.fieldTarget && this.fieldTarget.type == "date") {
      this.applyDateMask();
    } else if (this.fieldTarget && (this == null ? void 0 : this.patternValue)) {
      console.log("===pattern===", [this.patternValue, this.fieldTarget]);
      this.masked = IMask(this.fieldTarget, { mask: this.patternValue });
      this.fieldTarget.addEventListener(
        "keyup",
        (e) => this.checkPatternValidation(e)
      );
    }
  }
  perform(event) {
    var _a;
    if ((this == null ? void 0 : this.fieldTarget) && ((_a = this == null ? void 0 : this.fieldTarget) == null ? void 0 : _a.type) == "email") {
      this.fieldTarget.checkValidity() ? this.removeErrorState() : this.addErrorState();
    }
    if ((this == null ? void 0 : this.fieldTarget) && (this == null ? void 0 : this.patternValue)) {
      this.checkPatternValidation(event);
    }
  }
  checkEmailValidation(event) {
    console.log(event);
  }
  checkPatternValidation(e) {
    let isValid = false;
    const mask = this.masked;
    const enteredValueLength = e.target.value.length;
    this.addErrorState();
    console.log("meta===", mask);
    console.log("blocks", mask.masked._blocks.map((block) => block.isFixed || block.isFilled));
    const maskValid = mask.masked._blocks.reduce(
      (previousValue, block) => {
        const validBlock = block.isFixed || block.isFilled;
        return previousValue && validBlock;
      },
      true
    );
    if (maskValid) {
      isValid = true;
      this.removeErrorState();
      e.target.setCustomValidity("");
    } else {
      e.target.setCustomValidity("Expected format: " + mask.masked.mask);
    }
    console.log("isValid", isValid);
    if (enteredValueLength == 0)
      this.removeErrorState();
    console.log("checkPatternValidation", this.masked);
    return isValid;
  }
  addErrorState() {
    this.fieldTarget.style.setProperty("outline", "none");
    this.fieldTarget.style.setProperty("border", "1px solid red");
  }
  removeErrorState() {
    this.fieldTarget.style.removeProperty("border");
    this.fieldTarget.style.removeProperty("outline");
  }
  applyDateMask() {
    this.fieldTarget.pattern = "\\d{1,2}-\\d{1,2}-\\d{4}";
    this.fieldTarget.type = "text";
    const msInYear = 24 * 60 * 60 * 1e3 * 365;
    const maxYear = ["dob", "date_of_birth"].includes(this.fieldTarget.name) ? new Date().getFullYear() - 18 : new Date().getFullYear() + 1;
    this.masked = IMask(this.fieldTarget, {
      mask: Date,
      pattern: "m{-}`d{-}`Y",
      min: new Date(new Date() - msInYear * 120),
      max: new Date(maxYear, 11, 31),
      autofix: true,
      lazy: true,
      format: function(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (day < 10)
          day = "0" + day;
        if (month < 10)
          month = "0" + month;
        return [month, day, year].join("-");
      },
      parse: function(str) {
        var monthDayYear = str.split("-");
        return new Date(
          monthDayYear[2],
          monthDayYear[0] - 1,
          monthDayYear[1]
        );
      }
    });
  }
}
__publicField(field_validation_controller_default, "targets", ["field"]);
__publicField(field_validation_controller_default, "values", { pattern: String });
